import { stringify } from 'query-string';
import axios from 'axios';
// import users from './userData';
import API_DOMAIN from './apiDomain';
import { store } from '../store';

export async function checkUserLogin(credentials) {
  const response = await axios.post(`${API_DOMAIN}/user/v1/auth/login`, {
    user: { email: credentials.username, password: credentials.password },
  });
  return response;
}

export async function loginUser(credentials) {
  const response = await axios.post(`${API_DOMAIN}/user/v1/auth/verify_otp`, {
    id: credentials.id,
    otp: credentials.otp,
  });
  return response;
}

export async function forgotPassword(credentials) {
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/auth/forgot-password`,
    {
      user: {
        email: credentials.email,
        phone: credentials.phone,
        role: credentials.role,
      },
    }
  );
  return response;
}

export async function unblockRequest(username) {
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/auth/unblock-request`,
    {
      username,
    }
  );
  return response;
}

export async function unblockAdminOTP(credentials) {
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/auth/unblock-admin`,
    {
      id: credentials.id,
      otp: credentials.otp,
    }
  );
  return response;
}

export async function verifyForgotPasswordOTP(credentials) {
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/auth/reset-password-otp`,
    {
      id: credentials.id,
      otp: credentials.otp,
    }
  );
  return response;
}

export async function resetPassword(credentials) {
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/auth/reset-password-otp`,
    {
      id: credentials.id,
      otp: credentials.otp,
      password: credentials.password,
    }
  );
  return response;
}

export async function resendOTP(id) {
  const response = await axios.post(`${API_DOMAIN}/user/v1/auth/resend-otp`, {
    id,
  });
  return response;
}

export async function preregisterStudent(request, data) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/auth/preregistration-signup`;

  const response = await axios.post(url, data, options);
  return response;
}

export async function getStudentProfile(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/student`;

  const response = await axios.get(url, options);
  return response;
}

export async function fetchStudentDetailsByPhone(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const query = { searchOption: request.searchOption };
  const url = `${API_DOMAIN}/user/v1/csr/student-by-phone/${
    request.phoneNumber
  }?${stringify(query)}`;

  const response = await axios.get(url, options);
  return response;
}

export async function getLumpSumCourse(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/curriculum/v1/lumpsum-course/${request.licenseType}`;

  const response = await axios.get(url, options);
  return response;
}

export async function getPromotions(data) {
  const query = {
    courseId: data.courseId,
  };
  if (data.userId) {
    query.userId = data.userId;
  }
  const url = `${API_DOMAIN}/user/v1/promotions?${stringify(query)}`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = data.token ? data.token : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.get(url, options);
  return response;
}

export async function moveSkippedStage(courseId, stageCode, level) {
  const url = `${API_DOMAIN}/user/v1/move-skipped-stage-to-current`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.post(
    url,
    {
      courseId,
      stageCode,
      level,
    },
    options
  );
  return response;
}

export async function resendOTPByPhone(id, phone) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.put(
    `${API_DOMAIN}/user/v1/student/resend-otp-to-phone/${id}`,
    {
      phone,
    },
    options
  );
  return response;
}

export async function getStudentCourseTypeChangeData(id, onSuccess, onError) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  try {
    const response = await axios.get(
      `${API_DOMAIN}/user/v1/student/change-course-type/${id}`,
      options
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response;
  } catch (e) {
    let message = 'Unable to load course details';
    if (e && e.response && e.response.data) {
      message = e.response.data.message || message;
    }
    if (onError) {
      onError(message);
    }
  }
}

export async function getStudentCourseTypeToLumpSumChangeData(
  id,
  promoCode = null,
  onSuccess,
  onError,
  courseType = 'lumpsum'
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  try {
    const url = promoCode
      ? `${API_DOMAIN}/user/v1/student/change-course-type-to-${courseType}/${id}?promoCode=${promoCode}`
      : `${API_DOMAIN}/user/v1/student/change-course-type-to-${courseType}/${id}`;

    const response = await axios.get(url, options);
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response;
  } catch (e) {
    let message = 'Unable to load course details';
    if (e && e.response && e.response.data) {
      message = e.response.data.message || message;
    }
    if (onError) {
      onError(message);
    }
  }
}

export async function changeStudentCourseTypeToLumpSum(
  userId,
  data,
  onSuccess,
  onError,
  courseType = 'lumpSum'
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  try {
    const response = await axios.post(
      `${API_DOMAIN}/user/v1/student/change-course-type-to-${courseType}/${userId}`,
      data,
      options
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response;
  } catch (e) {
    let message = 'Unable to change course type';
    if (e && e.response && e.response.data) {
      message = e.response.data.message || message;
    }
    if (onError) {
      onError(message);
    }
  }
}

export async function changeStudentCourseType(
  userId,
  data,
  onSuccess,
  onError
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  try {
    const response = await axios.post(
      `${API_DOMAIN}/user/v1/student/change-course-type/${userId}`,
      data,
      options
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response;
  } catch (e) {
    let message = 'Unable to change course type';
    if (e && e.response && e.response.data) {
      message = e.response.data.message || message;
    }
    if (onError) {
      onError(message);
    }
  }
}

export async function getStudentCourseRuleChangeData(
  id,
  data,
  onSuccess,
  onError
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  try {
    const response = await axios.post(
      `${API_DOMAIN}/user/v1/student/change-course-rule/${id}`,
      data,
      options
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response;
  } catch (e) {
    let message = 'Unable to load course details';
    if (e && e.response && e.response.data) {
      message = e.response.data.message || message;
    }
    if (onError) {
      onError(message);
    }
  }
}

export async function changeStudentCourseRule(
  userId,
  data,
  onSuccess,
  onError
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  try {
    const response = await axios.put(
      `${API_DOMAIN}/user/v1/student/change-course-rule/${userId}`,
      data,
      options
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response;
  } catch (e) {
    let message = 'Unable to change course type';
    if (e && e.response && e.response.data) {
      message = e.response.data.message || message;
    }
    if (onError) {
      onError(message);
    }
  }
}

export async function revertAdditionalClass(userId, stageCode, level) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  try {
    const response = await axios.post(
      `${API_DOMAIN}/user/v1/revert-additional-training`,
      { userId, stageCode, level },
      options
    );
    return response;
  } catch (e) {
    let message = 'Unable to remove additional training';
    if (e && e.response && e.response.data) {
      message = e.response.data.message || message;
    }
  }
}

export async function getRefundDetails(id, isCorporate, onSuccess, onError) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  try {
    let link = 'refund-details';
    if (isCorporate) {
      link = 'corporate-refund-details';
    }
    const response = await axios.get(
      `${API_DOMAIN}/user/v1/student/${link}/${id}`,
      options
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response;
  } catch (e) {
    let message = 'Unable to load course details';
    if (e && e.response && e.response.data) {
      message = e.response.data.message || message;
    }
    if (onError) {
      onError(message);
    }
  }
}

export async function getRefundDetailsForUpgradeToLumpSum(
  id,
  isCorporate,
  onSuccess,
  onError
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  try {
    const link = 'upgrade-to-lumpsum-refund-details';

    const response = await axios.get(
      `${API_DOMAIN}/user/v1/student/${link}/${id}`,
      options
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response;
  } catch (e) {
    let message = 'Unable to load course details';
    if (e && e.response && e.response.data) {
      message = e.response.data.message || message;
    }
    if (onError) {
      onError(message);
    }
  }
}

export async function cancelInvoice(id, type) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/student/cancel-invoice/${id}`,
    { refundType: type, invoiceId: id },
    options
  );
  return response;
}

export async function sendRefundOtp(id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/student/send-refund-otp/${id}`,
    {},
    options
  );
  return response;
}

export async function refundAmount(
  id,
  otp,
  isChargeable,
  reason,
  isCorporateCustomer,
  canTakeEyeTestFee,
  canTakeAdditionalFees
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const link = isCorporateCustomer ? 'corporate-refund' : 'refund';
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/student/${link}/${id}`,
    { otp, isChargeable, reason, canTakeEyeTestFee, canTakeAdditionalFees },
    options
  );
  return response;
}

export async function fetchRTATestBookingDetails(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/csr/rta-booking-details/${request.trafficFileNo}`;

  const response = await axios.get(url, options);
  return response;
}

export async function updateInstructorBranch(instructorId, branchId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/instructor/${instructorId}/change-branch`,
    { branchId },
    options
  );
  return response;
}

export async function getCorporateDocuments(request) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/entity/v1/company/corporate-documents`;

  const response = await axios.get(url, options);
  return response;
}

export async function cancelCompanyInvoice(corporateId, id, type) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/corporate/${corporateId}/cancel-invoice/`,
    { refundType: type, invoiceId: id },
    options
  );
  return response;
}

export async function reverseTestResult(courseId, stage) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/reverse-test-result`,
    { courseId, instanceCode: stage.stage.code, level: stage.stage.level },
    options
  );
  return response;
}

export async function updateCourseConfig(userId, ignoreMaxClassesPerDay) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/csr/update-student-schedule-config`,
    { id: userId, ignoreMaxClassesPerDay },
    options
  );
  return response;
}

export async function updateCoursePartialScheduleConfig(
  userId,
  allowPartialScheduling
) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/csr/update-student-schedule-partial-config`,
    { id: userId, allowPartialScheduling },
    options
  );
  return response;
}

export async function fetchUserById(userId) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.get(
    `${API_DOMAIN}/user/v1/student/${userId}`,
    options
  );
  return response;
}

export async function updateReferralBonusStatus(bonusId, status) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const response = await axios.post(
    `${API_DOMAIN}/user/v1/referral-bonus/statusChange`,
    { bonusId, status },
    options
  );
  return response;
}
